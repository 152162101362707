<template>
  <div class="setting-page padding-left-layout padding-top-layout padding-right-layout">
    <page-title :title="textTitle" />
    <div :class="containerClass" >
      <router-link class="setting-btn" v-for="(item, index) in items" :key="index" :to="item.path" :class="{'is-user': isUser}">
        <div class="setting-btn-text">
          <div class="setting-btn-img">
            <img :src="getSettingIcon(item.image)" alt="" />
          </div>
          <div class="setting-text">{{ item.text }}</div>
        </div>
        <div class="arrow-svg"><img src="@/assets/images/setting/arrow.svg" alt="" /></div>
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { ROUTES } from '@/router/constants';
import {ROLE} from '@/constants/role';
import PageTitle from '@/components/pageTitle/pageTitle.vue';
export default {
  name: 'Setting',
  components: {
    PageTitle,
  },
  data() {
    return {
      breadcrum: [
        {
          text: this.$t('setting.hyperlink_home'),
          disabled: false,
          href: ROUTES.HOME_DASHBOARD,
        },
        {
          text: this.$t('setting.label_setting'),
          disabled: true,
          href: ROUTES.SETTING,
        },
      ],
      isHidden: false,
      // otherSettingText: this.$t("setting.button_other_setting"),
      enterpriseGroup: this.$t("group_enterprise_management.button_enterprise_management_setting"),
      items: [
        { key: 1, text: this.$t('setting.button_user_management'), path: ROUTES.USER_MANAGEMENT, image: 'user.svg' },
        { key: 2, text: this.$t('setting.button_facility_management'), path: ROUTES.FACILITY_MANAGEMENT, image: 'facility.svg' },
        { key: 3, text: this.$t('setting.button_management_viewer'), path: ROUTES.VIEWER_MANAGEMENT, image: 'viewer.svg' },
        { key: 4, text: this.$t('setting.button_fiscal_year'), path: ROUTES.CALCULATION_TERM, image: 'calculation.svg' },
        { key: 5, text: this.$t('setting.button_reduction_target'), path: ROUTES.REDUCTION_TARGET_LIST_MANAGEMENT, image: 'reduction.svg' },
        {
          key: 6,
          text: this.$t('setting.button_db_customize'),
          path: ROUTES.CREATE_DATA_CUSTOMIZE,
          image: 'db-customize.svg',
        },
        {
          key: 7,
          text: this.$t('setting.button_setting_auto_approval'),
          path: ROUTES.SETTING_AUTO_APPROVAL,
          image: 'auto-approval.svg',
        },
        {
          key: 8,
          text: this.$t('setting.button_setting_language'),
          path: ROUTES.SETTING_LANGUAGE,
          image: 'language.svg',
        },
        { key: 9, text: this.$t('setting.button_change_pass'), path: ROUTES.CHANGE_PASSWORD_USER, image: 'change-pass.svg' },
        {
          key: 10,
          text: this.$t('linked_service_management.button_linked_service_management_setting'),
          path: ROUTES.LINKED_SERVICE_MANAGEMENT,
          image: 'linked_service_management.svg',
        },
        {
          key: 11,
          text: this.$t('setting.button_setting_csv_format'),
          path: ROUTES.CSV_FORMAT,
          image: 'csv-format.svg',
        },
      ],
    };
  },
  mounted() {
    this.updateBreadCrumb(this.breadcrum);
    this.updateItems();
  },
  computed: {
    containerClass() {
      if(this.items.length % 3 === 1) return "container-setting settings-btn";
      return "settings-btn"
    },
    ...mapGetters(['getRoles']),
    ...mapState('userData', ['currentUser', 'planType', "existDbSource"]),
    isUser() {
      return this.currentUser.user.role_id === ROLE.APPROVAL || this.currentUser.user.role_id === ROLE.USER
    },
    textTitle() {
      return this.$t('setting.title_setting')
    }
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb']),
    getSettingIcon(image) {
      return require(`@/assets/images/setting/${image}`);
    },
    redirectPage(url) {
      return this.$router.push({ path: url });
    },
    updateItems() {
      const curentRole = this.currentUser.user.role_id
      if (curentRole === ROLE.APPROVAL || curentRole === ROLE.USER) {
        const listkeyByrole = curentRole === ROLE.APPROVAL ? [6, 7, 8, 9, 11] : [6, 8, 9, 11]
        const items = this.items.filter((item) => listkeyByrole.includes(item.key));
        this.items = items;
      } else {
        // TODO: Check res API
        const newDBSettingToInsert = { text: this.$t('idea_db_source.title_btn_setting_db_source'), path: this.existDbSource ? ROUTES.LIST_IDEA_DB_SOURCE : ROUTES.REGISTER_DB_SOURCE, image: 'idea-db-source.svg' };
        const index = 6;
        this.items.splice(index, 0, newDBSettingToInsert);
      }
    },
  },
  watch: {
    planType(newValue) {
      if (!(this.currentUser.user.role_id === ROLE.APPROVAL || this.currentUser.user.role_id === ROLE.USER)) {
        if (this.planType?.is_pcaf) {
          // if (this.items.findIndex(item => item.text === this.otherSettingText || item.text === this.enterpriseGroup) !== -1) {
          //   return
          // }
          if (this.items.findIndex(item => item.text === this.enterpriseGroup) > 0) {
            return
          }
          const itemEntepriseGroupInsert = { text: this.enterpriseGroup, path: ROUTES.GROUP_ENTERPRISE_MANAGEMENT, image: 'group-enterprise.svg' };
          // const itemToInsert = { text: this.otherSettingText, path: ROUTES.SETTING + ROUTES.OTHER_SETTING, image: 'other-settings.svg' };
          const indexToInsert = this.items.length;
          this.items.splice(indexToInsert, 0, itemEntepriseGroupInsert);
          // this.items.splice(indexToInsert, 0, itemEntepriseGroupInsert, itemToInsert);
        }
        if (!this.planType?.is_reduction_target) {
          const indexToRemove = this.items.findIndex(item => item.path === ROUTES.REDUCTION_TARGET_LIST_MANAGEMENT);
          if (indexToRemove !== -1) {
            this.items.splice(indexToRemove, 1);
          }
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.setting-page {
  .settings-btn {
    display: flex;
    flex-flow: column;
    .setting-btn {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $monoOffWhite;
      cursor: pointer;
      box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17), 0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13), 0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11);
      border-radius: 4px;
      margin-bottom: 16px;
      padding: 8px 8px 8px 12px;
      transition: 0.2s ease-out;
      .setting-btn-text {
        display: flex;
        align-items: center;
        gap: 12px;
        div {
          // padding-left: 12px;
          color: $monoBlack;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.03em;
          //text-align: center;
        }
      }
      &:hover {
        background-color: $monoWhite;
        box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17);
      }
      &:active {
        border: 2px solid $blueMid;
      }
      &.disabled {
        background: $monoLight;
        pointer-events: none;
        &:hover {
          cursor: unset;
        }
      }
    }
    .arrow-svg {
      width: 36px;
      height: 48px;
    }
  }
  .page-title {
    margin-bottom: 80px;
  }
}

@include desktop {
  .setting-page {
    .settings-btn {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      width: 100%;
      max-width: 640px;
      margin: 0 auto;
      padding-top: 32px;
      gap: 24px;
      &.container-setting {
        justify-content: flex-start;
      }
      .setting-btn {
        width: 197px;
        height: 136px;
        margin: 0px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding: unset;
        &:hover {
          cursor: pointer;
        }
        .arrow-svg {
          display: none;
        }
        .setting-btn-text {
          display: flex;
          flex-flow: column;
          gap: 0px;
          padding: 26px 10px 22px;
          img {
            flex-shrink: 0;
          }
          div {
            text-align: center;
          }
          .setting-btn-img {
            display: flex;
            width: 48px;
            height: 48px;
            padding: 6.5px 8.5px 7.54px 7.5px;
            justify-content: center;
            align-items: center;
          }
          .setting-text {
            margin-top: 16px;
          }
        }
        // &:nth-child(odd) {
        //   margin-left: unset;
        // }
        // &:nth-child(even) {
        //   margin-right: unset;
        // }
        // &.is-user {
        //   width: 304px;
        // }
      }
    }
  }
}
</style>
<style lang="scss">
@include desktop {
  #adminSetting {
    height: unset;
  }
}
</style>
